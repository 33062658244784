/* unplugin-vue-components disabled */import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VListItemContent } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"team"},[_c(VAutocomplete,{staticClass:"mb-2",attrs:{"items":_vm.teams,"label":_vm.label,"outlined":"","placeholder":_vm.placeholder,"hide-details":"","dense":"","clearable":"","deletable-chips":"","multiple":"","small-chips":"","item-text":"displayText","item-value":"extid"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItemContent,[_c('div',[_c('span',{staticClass:"typ-icon"},[_c('font-awesome-icon',{staticClass:"mr-1",class:{ "auto-team": item.typ === "autoteam" },attrs:{"size":"sm","icon":["fas", _vm.teamIcon(item)]}}),(item.farbe)?_c('span',{staticClass:"team-color",style:({ background: item.farbe })}):_vm._e(),_c('span',{staticClass:"team-label"},[_vm._v(_vm._s(item.name))]),(item.typ === "single" || item.typ === "mitarbeiter")?_c('span',{staticClass:"team-extid"},[_vm._v("["+_vm._s(item.id)+"]")]):_vm._e()],1)]),(item.typ === "team" || item.typ === "bundle" || item.typ === "autoteam")?_c('div',{staticClass:"member-list-or-name",attrs:{"title":_vm.teamTitle(item)}},[(item.size > 1)?_c('span',[_vm._v("("+_vm._s(item.size)+"):")]):_vm._e(),_vm._l((item.mitglieder),function(item,index){return _c('span',{key:item.id},[_vm._v(_vm._s(_vm._f("behandler")(item))),(!(index === item.size - 1))?_c('span',[_vm._v(",")]):_vm._e()])})],2):_vm._e(),(item.typ === "praxis")?_c('div',[_c('span',{staticClass:"member-list-or-name"},[_vm._v("Alle aktiven Behandler ("+_vm._s(item.size)+")")])]):_vm._e(),(item.typ === "alle")?_c('div',[_c('span',{staticClass:"member-list-or-name"},[_vm._v("Alle Behandler ("+_vm._s(item.size)+")")])]):_vm._e(),(item.typ === "unused")?_c('div',[_c('span',{staticClass:"member-list-or-name"},[_vm._v("Alle nicht konfigurierten Behandler ("+_vm._s(item.size)+")")])]):_vm._e()])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"option-selected"},[_c('span',{staticClass:"typ-icon"},[_c('font-awesome-icon',{staticClass:"mr-1",class:{ "auto-team": item.typ === "autoteam" },attrs:{"size":"sm","icon":["fas", _vm.teamIcon(item)]}})],1),(item.farbe)?_c('span',{staticClass:"team-color",style:({ background: item.farbe })}):_vm._e(),_c('span',{staticClass:"team-label"},[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.size)+")")]),(item.typ === "single" || item.typ === "mitarbeiter")?_c('span',{staticClass:"team-extid"},[_vm._v("["+_vm._s(item.id)+"]")]):_vm._e(),_c('span',{staticClass:"clear",on:{"click":function($event){return _vm.clear(item)}}},[_c('font-awesome-icon',{attrs:{"size":"sm","icon":["fas", "trash"]}})],1)])]}}]),model:{value:(_vm.localTeamSelected),callback:function ($$v) {_vm.localTeamSelected=$$v},expression:"localTeamSelected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }