import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import { defineComponent } from 'vue';
import { TeamTypes } from '../../../types';
import { teamIconClass } from '../../../common-ui';
import { chain, filter, find, findIndex, map } from 'lodash';
import { behandlerService } from '../services/behandlerService';
export default defineComponent({
  filters: {
    behandler: function behandler(value) {
      return behandlerService.transform(value);
    }
  },
  props: {
    teams: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    label: {
      type: String,
      "default": ''
    },
    placeholder: {
      type: String,
      "default": ''
    },
    teamSelected: {
      type: Array,
      "default": null
    },
    teamIdsSelected: {
      type: Array,
      "default": null
    },
    onlySingles: Boolean
  },
  data: function data() {
    var _this = this;

    return {
      localTeamSelected: this.teamSelected || map(this.teamIdsSelected, function (id) {
        return find(_this.teams, function (t) {
          return t.id === id;
        });
      })
    };
  },
  computed: {
    filteredTeams: function filteredTeams() {
      if (!this.teams) {
        return [];
      }

      if (this.onlySingles) {
        return filter(this.teams, function (team) {
          return team.typ === TeamTypes.SINGLE || team.typ === TeamTypes.BUNDLE;
        });
      }

      return this.teams;
    }
  },
  watch: {
    localTeamSelected: function localTeamSelected(teams) {
      this.$emit('update:teamSelected', teams);
      this.$emit('update:teamIdsSelected', map(teams, function (t) {
        return t.id;
      }));
    }
  },
  methods: {
    onTeamSelected: function onTeamSelected(val) {
      this.$emit('team-selected', val);
    },
    teamIcon: function teamIcon(team) {
      return teamIconClass(team);
    },
    teamTitle: function teamTitle(team) {
      return chain(team.mitglieder).compact().map(function (m) {
        return behandlerService.getProperty(m);
      }).join(', ');
    },
    clear: function clear(item) {
      console.log('Clear item: ', item);
      var index = findIndex(this.teamSelected, function (x) {
        return x.id === item.id;
      });

      if (index >= 0) {
        // eslint-disable-next-line vue/no-mutating-props
        this.teamSelected.splice(index, 1);
      }
    }
  }
});